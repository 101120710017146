import React from 'react';
import Select from 'react-select';

const MySelect = ({ options, id, isError, isMulti = false, placeholder, onChange, required = false }) => {
	return (
		<Select
			isMulti={ isMulti }
			isClearable
			id={ id }
			required={ required }
			placeholder={ placeholder }
			options={ options }
			onChange={ e => onChange(e) }
			styles={ {
				control: (styles, { isFocused }) => ({
					...styles,
					borderWidth: '1px',
					minHeight: '46.5px',
					borderRadius: '8px',
					border: `${isError ? '1px solid #e33434' : isFocused ? '1px solid #39AAB4' :  '1px solid #F4F4F4'}`,
					boxShadow: '0 !important',
					'&:hover': {
						border: `${isError ? '1px solid #e33434' : isFocused ? '1px solid #39AAB4' :  '1px solid #F4F4F4'}`,
					}
				}),
				option: (styles, { isFocused, isSelected }) => ({
					...styles,
					backgroundColor: (isFocused || isSelected) && 'rgba(57, 170, 180, 0.1)',
					color: isSelected ? '#39AAB4' : '#231F20',
					cursor: 'pointer',
					'&:hover': {
						backgroundColor: 'rgba(57, 170, 180, 0.1)',
					}
				}),
				input: styles => ({ ...styles, paddingTop: '0.25rem', paddingBottom: '0.25rem' }),
			} }
		/>
	);
};

export default MySelect;