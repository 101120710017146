/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiLogOut } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { coin } from '../../constant/images';
import { menus } from '../../constant/menus';
import AuthService from '../../services/auth';

import '../../styles/layout.css';

const RenderMenuWithSub = ({ menu, idx }) => {
	const [isOpen, setIsOpen] = useState(false);
	const location = useLocation();

	useEffect(() => {
		menu.submenu.filter(e => e.url === location.pathname).length > 0
			? setIsOpen(true)
			: setIsOpen(false);
	}, [location]);

	return (
		<li key={ idx }>
			<button
				onClick={ () => setIsOpen(!isOpen) }
				className='flex items-center justify-between submenu'
			>
				<div className={ 'flex items-center' }>
					<div className='icon'>{ menu.icon }</div>
					<span>{ menu.name }</span>
				</div>
				<FiChevronDown
					className={ `transition ${isOpen ? 'icon-up' : 'icon-down'}` }
					size={ 20 }
				/>
			</button>
			<ul
				className={ `menus transition ${isOpen ? 'menu-show' : 'menu-hide'}` }
				style={ { paddingTop: 'unset', listStyle: 'none' } }
			>
				{ menu.submenu.map((e, i) => {
					return (
						<li key={ i }>
							<Link
								style={ { paddingLeft: '40px' } }
								to={ e.url }
								className={ `flex items-center ${
									location.pathname === e.url && 'active'
								}` }
							>
								<span>{ e.name }</span>
							</Link>
						</li>
					);
				}) }
			</ul>
		</li>
	);
};

const Layouts = props => {
	const [processing, setProcessing] = useState(false);
	const [showNavbar, setShowNavbar] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const handleSignOut = async() => {
		setProcessing(true);

		try {
			let data = await AuthService.logout();
			if (data.status) {
				setProcessing(false);
				navigate('/login');
			} else {
				setProcessing(false);
			}
		} catch (e) {
			setProcessing(false);
		}
	};

	useEffect(() => {
		setShowNavbar(false);
	}, [location.pathname]);

	const renderMenu = (menu, idx) => {
		return (
			<li key={ idx }>
				<Link
					to={ menu.url }
					className={ `flex items-center ${
						location.pathname === menu.url && 'active'
					}` }
				>
					<div className='icon'>{ menu.icon }</div>
					<span>{ menu.name }</span>
				</Link>
			</li>
		);
	};

	return (
		<div className='layouts'>
			{ /* Sidebar */ }
			<div className={ `sidebar ${showNavbar && 'show-sidebar'}` }>
				<div className='logo'>
					<h2>Untitledexport</h2>
				</div>
				<ul className='menus'>
					{ menus.map((menu, idx) =>
						menu.submenu.length === 0 ? (
							renderMenu(menu, idx)
						) : (
							<RenderMenuWithSub
								key={ idx }
								menu={ menu }
								idx={ idx } />
						)
					) }
				</ul>
				<div className='footer'>
					<div className='profile'>
						<div className='avatar'>
							<img
								src={ coin }
								alt='avatar' />
						</div>
						<div>
							<h5>Logan Forbes</h5>
							<p>Admin</p>
						</div>
					</div>
					<button
						onClick={ handleSignOut }
						disabled={ processing }>
						<FiLogOut
							size={ 20 }
							color='red' />
					</button>
				</div>
			</div>

			<div
				// onClick={ () => setShowNavbar(false) }
				className='main'>
				{ /* topbar */ }
				<div className='topbar'>
					<h2>--- Announcements ----</h2>
					<button onClick={ () => setShowNavbar(!showNavbar) }>
						{
							showNavbar ? (
								<svg
									stroke='currentColor'
									fill='currentColor'
									strokeWidth='0'
									viewBox='0 0 16 16'
									height='1em'
									width='1em'
									xmlns='http://www.w3.org/2000/svg'><path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' /></svg>
							) : (
								<svg
									stroke='currentColor'
									fill='currentColor'
									strokeWidth='0'
									viewBox='0 0 16 16'
									className='humburger__icon'
									height='24'
									width='24'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										fillRule='evenodd'
										d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z' /></svg>
							)
						}
					</button>
				</div>

				{ /* content */ }
				<div
					onClick={ () => setShowNavbar(false) }
					className={ `content ${showNavbar && 'content-backdrop'}` }>{ props.children }</div>
			</div>
		</div>
	);
};

export default Layouts;
