/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Layouts from '../components/Layouts';
import Loading from '../components/Loading';
import AuthService from '../services/auth';

const AuthGuard = ({ component }) => {
	const [status, setStatus] = useState(false);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadingTimeout = setTimeout(() => {
			setLoading(false);
		}, 3000);

		return () => {
			clearTimeout(loadingTimeout);
		};
	}, []);

	useEffect(() => {
		checkToken();
	}, [component]);

	const checkToken = async() => {
		try {
			let user = await AuthService.getProfile(true);
			if (!user) {
				navigate('/login');
			}
			setStatus(true);
			return;
		} catch (error) {
			navigate('/login');
		}
	};

	return (status && !loading) ? <Layouts>{ component }</Layouts> : <Loading/>;
};

export default AuthGuard;