import React from 'react';

import '../styles/loading.css';

const Loading = () => {
	return (
		<div className='loading-indicator'>
			<div className='loading-container'>
				<div className='loading-spinner' />
				<h1>Loading CRM...</h1>
				<p className='login-footer'>
            &copy; 2023 Untitledexport. All rights reserved.
				</p>
			</div>
		</div>
	);
};

export default Loading;
