/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';

import { MySelect } from '../../components/index';
import FirestoreService from '../../services/firestore';

import './index.css';

const User = () => {
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [artistName, setArtistName] = useState('');
	const [emailAddress, setEmailAddress] = useState('');
	const [infoText, setInfoText] = useState('');
	const [agents, setAgents] = useState([]);
	const [selectedAgent, setSelectedAgent] = useState('');

	const handleNameChange = event => {
		setName(event.target.value);
	};

	const handlePhoneNumberChange = event => {
		setPhoneNumber(event.target.value);
	};

	const handleArtistNameChange = event => {
		setArtistName(event.target.value);
	};

	const handleEmailAddressChange = event => {
		setEmailAddress(event.target.value);
	};

	const handleInfoTextChange = event => {
		setInfoText(event.target.value);
	};

	const handleSubmit = async event => {
		event.preventDefault();

		if (
			phoneNumber.trim() !== '' &&
      artistName.trim() !== '' &&
      emailAddress.trim() !== '' &&
      selectedAgent.trim() !== ''
		) {
			await FirestoreService.createUser(
				name,
				phoneNumber,
				artistName,
				emailAddress,
				infoText,
				selectedAgent
			);
			// console.log('New user created:', newUser);

			// Reset the form fields
			setName('');
			setPhoneNumber('');
			setArtistName('');
			setEmailAddress('');
			setInfoText('');
			setSelectedAgent('');
		} else {
			window.alert('Please fill in all required fields.');
		}
	};

	const handleAgentChange = event => {
		setSelectedAgent(event?.value);
	};

	useEffect(() => {
		const getAgentsList = async() => {
			try {
				const agentsList = await FirestoreService.getAgents();
				setAgents(agentsList);
			} catch (error) {
				// console.log(error);
			}
		};

		getAgentsList();
	}, []);

	return (
		<div className='create-user-container px-5'>
			<div className='create-user-header'>
				<h2>Record a new User</h2>
				<p>
            Complete all of the fields in this form to add a new user in our CRM
            Database.
				</p>
			</div>
			<form
				style={ { marginTop: '24px' } }
				className='card grid grid-col-3'
				onSubmit={ handleSubmit }>
				<div className='col-span-3 form-input'>
					<label htmlFor='agent'> Agent </label>
					<MySelect
						id='agent'
						required
						onChange={ e => handleAgentChange(e) }
						placeholder='Select an agent...'
						options={ agents?.map(e => {
							return {
								value: e,
								label: e
							};
						}) } />
				</div>
				<div className='form-input'>
					<label htmlFor='artist-name-input'>Artist Name</label>
					<input
						id='artist-name-input'
						value={ artistName }
						required
						onChange={ handleArtistNameChange }
						placeholder='Artist Name'
					/>
				</div>
				<div className='form-input'>
					<label htmlFor='name-input'>Full Name <span>(Optional)</span></label>
					<input
						id='name-input'
						value={ name }
						placeholder='Full Name'
						onChange={ handleNameChange }
					/>
				</div>
				<div className='form-input'>
					<label htmlFor='phone-number-input'>Phone Number</label>
					<input
						type='tel'
						required
						id='phone-number-input'
						value={ phoneNumber }
						placeholder='Phone Number'
						onChange={ handlePhoneNumberChange }
					/>
				</div>
				<div className='form-input col-span-3'>
					<label htmlFor='email-address-input'>Email Address</label>
					<input
						type='email'
						required
						id='email-address-input'
						value={ emailAddress }
						placeholder='Email Address'
						onChange={ handleEmailAddressChange }
					/>
				</div>
				<div className='form-input col-span-3'>
					<label htmlFor='info-text-input'>Note</label>
					<textarea
						id='info-text-input'
						value={ infoText }
						rows={ 8 }
						required
						onChange={ handleInfoTextChange }
						placeholder='Any additional information about the client can be listed here.&#10;Some examples include: Instagram Link, Spotify Link, Website, YouTube, etc.'
					/>
				</div>
				<div className='col-span-3 flex'>
					<button
						style={ { marginLeft: 'auto', marginRight: 'auto', width: '30%' } }
						className='submit-button'
						type='submit'>
						Create User
					</button>
				</div>
			</form>
		</div>
	);
};

export default User;
