import React, { useEffect, useState } from 'react';

import { MySelect } from '../../components';
import FirestoreService from '../../services/firestore';

import './index.css';

const GenerateInvoice = () => {
	const [isGenerating, setIsGenerating] = useState(false);
	const [billTo, setBillTo] = useState('');
	const [items, setItems] = useState([
		{ name: '', quantity: '', unit_cost: '' },
	]);
	const [notes, setNotes] = useState('');
	const [terms, setTerms] = useState('');
	const [discounts, setDiscounts] = useState('');
	const [currentInvoiceNumber, setCurrentInvoiceNumber] = useState(0);
	const [invoiceItems, setInvoiceItems] = useState([]);

	const addItem = () => {
		setItems([...items, { name: '', quantity: 1, unit_cost: 0 }]);
	};

	const updateItem = (index, updatedItem) => {
		setItems(items.map((item, i) => (i === index ? updatedItem : item)));
	};

	// const removeItem = (index) => {
	//   setItems(items.filter((item, i) => i !== index));
	// };

	const generateInvoice = async() => {
		setIsGenerating(true);

		const invoice = {
			logo: 'https://firebasestorage.googleapis.com/v0/b/untitledexport-crm.appspot.com/o/1024.png?alt=media&token=5d5887d3-d017-408d-8585-365733f49c21',
			from: 'Untitledexport MGMT, LLC\n12 Daniel Rd\nFairfield, NJ 07004',
			to: billTo,
			currency: 'usd',
			number: `Invoice ${currentInvoiceNumber}`,
			payment_terms: 'Upon Receipt',
			due_date: 'Same day',
			items: items,
			fields: {
				tax: '%',
				discounts: true,
			},
			tax: '0',
			discounts: discounts,
			notes: notes,
			terms: terms,
		};

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(invoice),
		};

		try {
			const response = await fetch('/api/invoice', requestOptions);
			const blob = await response.blob();

			// Create a download link for the invoice PDF
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Invoice_${currentInvoiceNumber}.pdf`);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);

			const newInvoiceNumber = currentInvoiceNumber + 1;

			await FirestoreService.updateInvoiceNumber(newInvoiceNumber);
			setCurrentInvoiceNumber(newInvoiceNumber);
		} catch (error) {
			// console.error(error);
		}

		setIsGenerating(false);
	};

	useEffect(() => {
		const getCurrentInvoiceNumber = async() => {
			try {
				const invoiceNumber = await FirestoreService.getCurrentInvoiceNumber();
				setCurrentInvoiceNumber(invoiceNumber);

				const items = await FirestoreService.getInvoiceItems();
				setInvoiceItems(items);
			} catch (error) {
				// console.error(error);
			}
		};

		getCurrentInvoiceNumber();
	}, []);

	return (
		<div style={ {
			maxWidth: '678px',
			marginLeft: 'auto',
			marginRight: 'auto'
		} }>
			<h1>Generate Invoice</h1>
			<form
				style={ { marginTop: '24px' } }
				className='card grid grid-col-3'
				onSubmit={ e => e.preventDefault() }>
				<div className='col-span-3 form-input'>
					<label htmlFor='billTo'>Customer Name</label>
					<input
						id='billTo'
						value={ billTo }
						placeholder='Customer Name'
						onChange={ e => setBillTo(e.target.value) }
					/>
				</div>
				<div className='col-span-3 '>
					<br/>
					<hr/>
					<br/>
				</div>

				<h3 className='col-span-3'>Items:</h3>
				{ items.map((item, index) => (
					<div
						key={ index }
						className='col-span-3 grid grid-col-3'>
						<div className='form-input'>
							<label htmlFor={ `item-name-${index}` }> Select an Item </label>
							<MySelect
								id={ `item-name-${index}` }
								required
								onChange={ e =>
									updateItem(index, {
										...item,
										name: e?.value,
									}) }
								placeholder='Select an item...'
								options={ invoiceItems?.map(e => {
									return {
										value: e,
										label: e
									};
								}) } />
						</div>
						<div className='form-input'>
							<label htmlFor={ `item-quantity-${index}` }> Quantity </label>
							<input
								type='number'
								placeholder='Quantity'
								onWheel={ e => e.currentTarget.blur() }
								id={ `item-quantity-${index}` }
								value={ item.quantity }
								onChange={ e =>
									updateItem(index, {
										...item,
										quantity: parseInt(e.target.value),
									}) }
							/>
						</div>
						<div className='form-input'>
							<label htmlFor={ `item-unit-cost-${index}` }> Unit Cost <span>(Dollars)</span> </label>
							<input
								type='number'
								placeholder='Unit Cost (Dollars)'
								onWheel={ e => e.currentTarget.blur() }
								id={ `item-unit-cost-${index}` }
								value={ item.unit_cost }
								onChange={ e =>
									updateItem(index, {
										...item,
										unit_cost: parseFloat(e.target.value),
									}) }
							/>
						</div>
						{ /* TODO: Remove item later on */ }
						{ /* <button
              style={{ backgroundColor: "red", marginBottom: "24px" }}
              type="button"
              onClick={() => removeItem(index)}
            >
              Remove Item
            </button> */ }
					</div>
				)) }
				<div className='col-span-3 flex items-center justify-center'>
					<button
						className='Add-Button'
						type='button'
						onClick={ addItem }>
						Add Item
					</button>
				</div>
				<div className='col-span-3 '>
					<br/>
					<hr/>
					<br/>
				</div>
				<div className='col-span-3 form-input'>
					<label htmlFor='notes'> Notes here </label>
					<textarea
						rows={ 5 }
						id='notes'
						placeholder='Notes here...'
						value={ notes }
						onChange={ e => setNotes(e.target.value) }
					/>
				</div>
				<div className='col-span-3 form-input'>
					<label htmlFor='terms'> Terms and conditions </label>
					<textarea
						rows={ 5 }
						id='terms'
						placeholder='Terms and conditions'
						value={ terms }
						onChange={ e => setTerms(e.target.value) }
					/>
				</div>

				<div className='col-span-3 form-input'>
					<label htmlFor='discount'> Discount <span>(Dollars)</span> </label>
					<input
						id='discount'
						type='number'
						placeholder='Discount (Dollars)'
						onWheel={ e => e.currentTarget.blur() }
						value={ discounts }
						onChange={ e => setDiscounts(parseFloat(e.target.value)) }
					/>
				</div>

				<div className='col-span-3 flex items-center justify-center'>
					<button
						onClick={ generateInvoice }
						disabled={ isGenerating }
						type='submit'>
						{ isGenerating ? 'Generating Invoice...' : 'Generate Invoice' }
					</button>
				</div>

			</form>
		</div>
	);
};

export default GenerateInvoice;
