import React from 'react';
import { Grid } from 'gridjs-react';

import 'gridjs/dist/theme/mermaid.css';

const TableComponent = props => {
	return (
		<Grid
			language= { {
				search: {
					placeholder: props.placeholder ?? '🔍 Search...'
				}
			} }
			data={ props.data }
			resizable={ true }
			columns={ props.columns }
			search={ true }
			sort={ true }
			fixedHeader={ true }
			pagination={ {
				limit: 10,
			} }
		/>
	);
};

function compare(prevProps, nextProps) {
	return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}

const Table = React.memo(TableComponent, compare);

export default Table;