import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { _ } from 'gridjs-react';

import { MySelect, Table } from '../../components/index';
import { formatShortDate } from '../../helpers/date';
import FirestoreService from '../../services/firestore';

import './index.css';

const Dashboard = () => {
	const [users, setUsers] = useState([]);
	const [agents, setAgents] = useState([]);

	const [selectedAgent, setSelectedAgent] = useState('');

	const navigate = useNavigate();

	useEffect(() => {
		const loadUsers = async() => {
			const users = await FirestoreService.getUsers();
			const sortedUsers = users.sort(
				(a, b) =>
					new Date(b.createdAt.seconds * 1000) -
          new Date(a.createdAt.seconds * 1000)
			);
			setUsers(sortedUsers);
		};

		const loadAgents = async() => {
			const agentList = await FirestoreService.getAgents();
			setAgents(agentList);
		};
		loadUsers();
		loadAgents();
	}, []);

	const handleAgentChange = event => {
		if (event?.value) {
			setSelectedAgent(event.value);
		} else {
			setSelectedAgent('');
		}
	};

	const handleViewUser = userId => {
		navigate({
			pathname: '/updateClient',
			search: `id=${userId}`,
		});
	};

	const filteredUsers = users.filter(user => {
		const agentMatches = !selectedAgent || user.agent === selectedAgent;
		return agentMatches;
	});

	return (
		<div className='dashboard-container'>
			<div className='table-container'>
				<div className='filter_agent'>
					<MySelect
						onChange={ e => handleAgentChange(e) }
						placeholder='Filter by agent...'
						options={ agents.map(e => {
							return {
								value: e,
								label: e
							};
						}) } />
				</div>
				<Table
					data={ filteredUsers }
					columns={ [
						{
							name: 'Created Date	',
							id: 'createdAt',
							formatter: cell => {
								return _(<div>{ formatShortDate(cell) }</div>);
							},
						},
						{
							name: 'Artist Name',
							id: 'artistName'
						},
						{
							name: 'Agent',
							id: 'agent'
						},
						{
							name: 'Phone Number',
							id: 'phoneNumber',
						},
						{
							name: 'Email Address',
							id: 'emailAddress',
						},
						{
							name: '',
							id: 'id',
							sort: false,
							formatter: cell => {
								return _(<button
									className='update-button'
									onClick={ () => handleViewUser(cell) }
								>
            View
								</button>);
							},
						},
            
					] }
				/>
			</div>
		</div>
	);
};

export default Dashboard;
