import React from 'react';

const SubmenuA = () => {
	return (
		<div>
			<p>SubmenuA</p>
		</div>
	);
};

export default SubmenuA;