import { initializeApp } from '@firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import FIREBASE_CONFIG from '../config/firebase';

initializeApp(FIREBASE_CONFIG);

const AuthService = {};

AuthService.login = (email, password) => {
	const fauth = getAuth();

	return new Promise(resolve => {
		signInWithEmailAndPassword(fauth, email, password).then(user => {
			if (user) {
				resolve({ status: true, message: 'Login successfully.' });
			} else {
				resolve({ status: false, message: 'Incorrect Email or Password.' });
			}
		})
			.catch(() => {
				resolve({ status: false, message: 'Incorrect Email or Password.' });
			});
	});

};

AuthService.logout = async() => {
	return new Promise(resolve => {
		const fauth = getAuth();
		fauth.signOut().then(() => {
			resolve({ status: true, message: 'Logged out successfully.' });
		})
			.catch(() => {
				resolve({ status: true, message: 'Logged out successfully.' });
			});
	});
};

AuthService.user = false;
AuthService.getProfile = () => {
	return new Promise(res => {

		const fauth = getAuth();

		fauth.onAuthStateChanged(user => {
			if (user) {
				res(user);
			} else {
				res(false);
			}
		});

	});
};

export default AuthService;