import React from 'react';

const SubmenuB = () => {
	return (
		<div>
			<p>SubmenuB</p>
		</div>
	);
};

export default SubmenuB;