import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import FirestoreService from '../../services/firestore';

const UpdateClient = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const userId = searchParams.get('id');
	const [user, setUser] = useState(null);
	const [isEditMode, setIsEditMode] = useState(false);

	useEffect(() => {
		const loadUser = async() => {
			const user = await FirestoreService.getUser(userId);
			if (!user.notes) {
				user.notes = [];
			}
			setUser(user);
		};

		if (userId) {
			loadUser();
		}
	}, [userId]);

	const toggleEditMode = () => {
		setIsEditMode(!isEditMode);
	};

	const handleNameChange = event => {
		setUser({ ...user, name: event.target.value });
	};

	const handlePhoneNumberChange = event => {
		setUser({ ...user, phoneNumber: event.target.value });
	};

	const handleAgentChange = event => {
		setUser({ ...user, agent: event.target.value });
	};

	const handleArtistNameChange = event => {
		setUser({ ...user, artistName: event.target.value });
	};

	const handleEmailAddressChange = event => {
		setUser({ ...user, emailAddress: event.target.value });
	};

	const handleInfoTextChange = event => {
		setUser({ ...user, infoText: event.target.value });
	};

	const handleNoteChange = (index, event) => {
		const newNotes = [...user.notes];
		newNotes[index].text = event.target.value;
		setUser({ ...user, notes: newNotes });
	};

	const handleAddNote = () => {
		const newNote = {
			text: '',
			date: new Date().toISOString(),
		};
		setUser({ ...user, notes: [...user.notes, newNote] });
	};

	const handleRemoveNote = index => {
		const newNotes = user.notes.filter((_, i) => i !== index);
		setUser({ ...user, notes: newNotes });
	};

	const handleSubmit = async event => {
		event.preventDefault();
		try {
			const updatedUser = await FirestoreService.updateUser(user.id, user);
			setUser(updatedUser);
			toggleEditMode();
		} catch (error) {
			// console.error(error);
			window.alert('Error updating user.');
		}
	};

	const formatShortDate = date => {
		const options = {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		};
		const formattedDate = date
			? new Date(date).toLocaleString('en-US', options)
			: '-';
		return formattedDate;
	};

	return (
		<div>
			<div
				style={ {
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					maxWidth: '678px',
					marginLeft: 'auto',
					marginRight: 'auto'
				} }
			>
				<h1 style={ { paddingBottom: '24px' } }>Client Info</h1>
				<button
					style={ { marginBottom: '24px' } }
					onClick={ toggleEditMode }>
					{ isEditMode ? 'Done' : 'Edit' }
				</button>
			</div>

			{ userId ? (
				user ? (
					<form
						style={ {
							maxWidth: '678px',
							marginLeft: 'auto',
							marginRight: 'auto'
						} }
						onSubmit={ handleSubmit }
						className='card grid grid-col-3'>
						<div className='form-input'>
							<label htmlFor='artistName'>Artist Name</label>
							<input
								id='artistName'
								value={ user.artistName }
								onChange={ handleArtistNameChange }
								placeholder='Artist Name'
								readOnly={ !isEditMode }
							/>
						</div>
						<div className='form-input'>
							<label htmlFor='name'>Full Name <span>(Optional)</span></label>
							<input
								placeholder='Full Name'
								id='name'
								value={ user.name }
								onChange={ handleNameChange }
								readOnly={ !isEditMode }
							/>
						</div>
						<div className='form-input'>
							<label htmlFor='phoneNumber'>Phone Number</label>
							<input
								id='phoneNumber'
								readOnly={ !isEditMode }
								value={ user.phoneNumber }
								onChange={ handlePhoneNumberChange }
								placeholder='Phone Number'
							/>
						</div>
						<div className='form-input'>
							<label htmlFor='emailAddress'>Email</label>
							<input
								type='email'
								readOnly={ !isEditMode }
								id='emailAddress'
								value={ user.emailAddress }
								onChange={ handleEmailAddressChange }
							/>
						</div>
						<div className='form-input'>
							<label htmlFor='lastModified'>Last updated</label>
							<input
								readOnly={ true }
								id='lastModified'
								value={ `${formatShortDate(user.updatedAt)}` }
							/>
						</div>
						<div className='form-input'>
							<label htmlFor='createdAt'>Created on</label>
							<input
								readOnly={ true }
								id='createdAt'
								value={ `${formatShortDate(user.createdAt)}` }
							/>
						</div>
						<div className='form-input col-span-3'>
							<label htmlFor='infoText'>Info</label>
							<textarea
								id='infoText'
								rows={ 8 }
								readOnly={ !isEditMode }
								value={ user.infoText }
								onChange={ handleInfoTextChange }
								placeholder='Any additional information about the client can be listed here.&#10;Some examples include: Instagram Link, Spotify Link, Website, YouTube, etc.'
							/>
						</div>
						<div className='form-input col-span-3'>
							<label htmlFor='agent'>Agent</label>
							<input
								id='agent'
								readOnly={ true }
								value={ `${user.agent}` }
								onChange={ handleAgentChange }
								placeholder='Agent'
							/>
						</div>
						<div className='col-span-3 '>
							<br/>
							<hr/>
							<br/>
						</div>
						<h3>Notes</h3>
						{ user.notes.map((note, index) => (
							<div
								key={ index }
								className='col-span-3'>
								<p>Date: { formatShortDate(note.date) }</p>
								<div className='form-input'>
									<textarea
										rows={ 3 }
										style={ {
											marginBottom: '12px',
											marginTop: '12px',
											width: '100%',
											minHeight: '60px',
										} }
										readOnly={ !isEditMode }
										value={ note.text }
										onChange={ e => handleNoteChange(index, e) }
									/>
								</div>
								{ isEditMode && (
									<button
										style={ {
											backgroundColor: 'red',
											fontSize: '14px',
											marginBottom: '12px',
											fontWeight: '300',
										} }
										type='button'
										onClick={ () => handleRemoveNote(index) }
									>
                    Remove Note
									</button>
								) }
							</div>
						)) }

						{ isEditMode && (
							<div className='col-span-3 flex'>
								<button
									style={ { marginTop: '12px', marginBottom: '24px', marginLeft: 'auto', marginRight: 'auto' } }
									type='button'
									onClick={ handleAddNote }
								>
									Add Note
								</button>
							</div>
						) }

						{ isEditMode && (
							<div className='col-span-3 flex'>
								<button
									style={ { backgroundColor: 'salmon', marginTop: '12px', marginLeft: 'auto', marginRight: 'auto' } }
									type='submit'
								>
									Update User
								</button>
							</div>
						) }
					</form>
				) : (
					<p>Loading user...</p>
				)
			) : (
				<p style={ { textAlign: 'center', padding: '24px' } }>
          Please go to the{ ' ' }
					<a
						style={ { color: 'salmon' } }
						href='/dashboard'>
            Dashboard Page
					</a>{ ' ' }
          to select a user to update.
				</p>
			) }
		</div>
	);
};

export default UpdateClient;
