import React from 'react';

import './index.css';

const Saved = () => {
	return (
		<div>
			<p>Saved</p>
		</div>
	);
};

export default Saved;