import {
	FiHome,
	// FiFile,
	FiMessageCircle,
	// FiHeart,
	FiShoppingCart,
	// FiPieChart,
	FiUser,
	// FiSettings,
} from 'react-icons/fi';

export const menus = [
	{
		url: '/dashboard',
		name: 'Dashboard',
		icon: <FiHome />,
		submenu: [],
	},
	{
		url: '/user',
		name: 'Record Client',
		icon: <FiUser />,
		submenu: [],
	},
	{
		url: '/updateClient',
		name: 'Client Info',
		icon: <FiMessageCircle />,
		submenu: [],
	},
	{
		url: '/generateInvoice',
		name: 'Generate Invoice',
		icon: <FiShoppingCart />,
		submenu: [],
	},
	// {
	//   url : '/analytics',
	//   name : 'analytics',
	//   icon : <FiPieChart/>,
	//   submenu : []
	// },
	// {
	//   url : '/file-manager',
	//   name : 'File Manager',
	//   icon : <FiFile/>,
	//   submenu : []
	// },
	// {
	//   url : '/saved',
	//   name : 'Saved',
	//   icon : <FiHeart/>,
	//   submenu : []
	// },
	// {
	//   url: null,
	//   name: "Settings",
	//   icon: <FiSettings />,
	//   submenu: [
	//     {
	//       url: "/sub-menu-a",
	//       name: "Submenu A",
	//     },
	//     {
	//       url: "/sub-menu-b",
	//       name: "Submenu B",
	//     },
	//   ],
	// },
];
