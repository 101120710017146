import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { coin } from '../../constant/images';
import AuthService from '../../services/auth';

import './index.css';

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [processing, setProcessing] = useState(false);

	const [error, setError] = useState('');
	const navigate = useNavigate();

	const handleSubmit = async event => {
		event.preventDefault();
		setError('');
		setProcessing(true);

		try {
			let data = await AuthService.login(email, password);
			if (data.status) {
				setProcessing(false);
				navigate('/dashboard');
			} else {
				setError(data.message);
				setProcessing(false);
			}
		} catch (error) {
			setError(error.message);
		}
	};

	return (
		<div className='login-container'>
			<form
				className='login-form grid grid-col-1'
				onSubmit={ handleSubmit }>
				<img
					src={ coin }
					className='App-logo'
					alt='logo' />
				<h1 style={ { paddingBottom: '24px' } }>CRM Portal</h1>
				<div className='form-input'>
					<label htmlFor='email'>Email</label>
					<input
						type='email'
						id='email'
						placeholder='Email'
						value={ email }
						onChange={ e => setEmail(e.target.value) }
						required
						autoComplete='off'
					/>
				</div>
				<div className='form-input'>
					<label htmlFor='password'>Password</label>
					<input
						id='password'
						type='password'
						placeholder='Password'
						value={ password }
						onChange={ e => setPassword(e.target.value) }
						required
						autoComplete='off'
					/>
				</div>
			
				{ error && <div className='error'>{ error }</div> }
				<button
					type='submit'
					disabled={ processing }>
					{ processing ? 'Loading...' : 'Login' }
				</button>
				{ /* <p style={{ textAlign: "center" }}>(Authorized users only)</p> */ }
				<p className='login-footer'>
          &copy; 2023 Untitledexport. All rights reserved.
				</p>
			</form>
		</div>
	);
};

export default Login;
