import React from 'react';
import { Route } from 'react-router-dom';

import AuthGuard from '../guards/AuthGuard';
import {
	Analytics,
	Dashboard,
	FileManager,
	GenerateInvoice,
	Saved,
	SubMenuA,
	SubMenuB,
	UpdateClient,
	User,
} from '../pages';

const AuthRoutes = [
	<Route
		path='/'
		key='/'
		element={ <AuthGuard component={ <Dashboard /> } /> } />,
	<Route
		key='/dashboard'
		path='/dashboard'
		element={ <AuthGuard component={ <Dashboard /> } /> } />,
	<Route
		key='/user'
		path='/user'
		element={ <AuthGuard component={ <User /> } /> } />,
	<Route
		key='/updateClient'
		path='/updateClient'
		element={ <AuthGuard component={ <UpdateClient /> } /> }
	/>,
	<Route
		key='/analytics'
		path='/analytics'
		element={ <AuthGuard component={ <Analytics /> } /> } />,
	<Route
		key='/generateInvoice'
		path='/generateInvoice'
		element={ <AuthGuard component={ <GenerateInvoice /> } /> }
	/>,
	<Route
		key='/saved'
		path='/saved'
		element={ <AuthGuard component={ <Saved /> } /> } />,
	<Route
		key='/file-manager'
		path='/file-manager'
		element={ <AuthGuard component={ <FileManager /> } /> }
	/>,
	<Route
		key='/sub-menu-a'
		path='/sub-menu-a'
		element={ <AuthGuard component={ <SubMenuA /> } /> } />,
	<Route
		key='/sub-menu-b'
		path='/sub-menu-b'
		element={ <AuthGuard component={ <SubMenuB /> } /> } />,
];

export default AuthRoutes;
