import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	getFirestore,
	orderBy,
	query,
	setDoc,
	where,
} from 'firebase/firestore';
const db = getFirestore();

const FirestoreService = {};

FirestoreService.createUser = async(
	name,
	phoneNumber,
	artistName,
	emailAddress,
	infoText,
	agent
) => {
	const usersRef = collection(db, 'users');
	const newDocRef = await addDoc(usersRef, {
		name: name || '',
		phoneNumber: phoneNumber || '',
		artistName: artistName || '',
		emailAddress: emailAddress || '',
		infoText: infoText || '',
		agent: agent || '',
		createdAt: new Date(),
		updatedAt: new Date(),
	});
	const newUserData = {
		id: newDocRef.id,
		name: name || '',
		phoneNumber: phoneNumber || '',
		artistName: artistName || '',
		emailAddress: emailAddress || '',
		infoText: infoText || '',
		agent: agent || '',
		createdAt: new Date(),
		updatedAt: new Date(),
	};
	return newUserData;
};

FirestoreService.getUsers = async() => {
	const usersRef = collection(db, 'users');
	const q = query(usersRef, orderBy('name'), where('name', '>=', ''));
	const querySnapshot = await getDocs(q);
	const users = querySnapshot.docs.map(doc => {
		return {
			id: doc.id,
			...doc.data(),
		};
	});
	return users;
};

FirestoreService.getUser = async userId => {
	const userRef = doc(db, 'users', userId);
	const userDoc = await getDoc(userRef);
	if (userDoc.exists()) {
		const user = {
			id: userDoc.id,
			...userDoc.data(),
			createdAt: userDoc.data().createdAt.toDate(),
			updatedAt: userDoc.data().updatedAt.toDate(),
		};
		return user;
	} else {
		throw new Error(`User with ID ${userId} does not exist.`);
	}
};

FirestoreService.updateUser = async(userId, newData) => {
	const userRef = doc(db, 'users', userId);
	const updatedAt = new Date();
	await setDoc(userRef, { ...newData, updatedAt }, { merge: true });
	const userDoc = await getDoc(userRef);
	const updatedUser = {
		id: userDoc.id,
		...userDoc.data(),
		createdAt: userDoc.data().createdAt.toDate(),
		updatedAt: userDoc.data().updatedAt.toDate(),
	};
	return updatedUser;
};

FirestoreService.getCurrentInvoiceNumber = async() => {
	const invoiceDoc = await getDoc(doc(db, 'config', 'invoice'));
	if (invoiceDoc.exists()) {
		return invoiceDoc.data().currentInvoiceNumber;
	} else {
		throw new Error('Unable to retrieve current invoice number.');
	}
};

FirestoreService.updateInvoiceNumber = async newInvoiceNumber => {
	await setDoc(
		doc(db, 'config', 'invoice'),
		{ currentInvoiceNumber: newInvoiceNumber },
		{ merge: true }
	);
};

FirestoreService.getInvoiceItems = async() => {
	const invoiceDoc = await getDoc(doc(db, 'config', 'invoice'));
	if (invoiceDoc.exists()) {
		const invoiceItems = invoiceDoc.data().invoiceItems || [];
		return invoiceItems;
	} else {
		throw new Error('Unable to retrieve invoice items.');
	}
};

FirestoreService.getAgents = async() => {
	const agentsDocRef = doc(db, 'config', 'agents');
	const agentsDoc = await getDoc(agentsDocRef);

	if (agentsDoc.exists()) {
		const agentList = agentsDoc.data().agentList || [];
		return agentList;
	} else {
		throw new Error('Unable to retrieve agents list.');
	}
};

export default FirestoreService;
