import { BrowserRouter, Routes } from 'react-router-dom';

import AuthRoutes from './router/AuthRoutes';
import UnAuthRoutes from './router/UnAuthRoutes';

import './styles/global.css';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				{ AuthRoutes }
				{ UnAuthRoutes }
			</Routes>
		</BrowserRouter>
	);
}

export default App;
