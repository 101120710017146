const FIREBASE_CONFIG = {
	apiKey: 'AIzaSyDBt8BujFMRNGmK423RAlqFBQ3zjGbm6og',
	authDomain: 'untitledexport-crm.firebaseapp.com',
	projectId: 'untitledexport-crm',
	storageBucket: 'untitledexport-crm.appspot.com',
	messagingSenderId: '399385609048',
	appId: '1:399385609048:web:191d8eef351958c31a5b2d',
	measurementId: 'G-1ZKHCBRCY6',
};

export default  FIREBASE_CONFIG;
