import React from 'react';

import './index.css';

const FileManager = () => {
	return (
		<div>
			<p>FileManager</p>
		</div>
	);
};

export default FileManager;