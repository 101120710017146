/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loading from '../components/Loading';
import AuthService from '../services/auth';

const UnAuthGuard = ({ component }) => {
	const [status, setStatus] = useState(false);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadingTimeout = setTimeout(() => {
			setLoading(false);
		}, 3000);
  
		return () => {
			clearTimeout(loadingTimeout);
		};
	}, []);

	useEffect(() => {
		checkToken();
	}, [component]);

	const checkToken = async() => {
		try {
			let user = await AuthService.getProfile();
			if (!user) {
				localStorage.removeItem('token');
			} else {
				navigate('/');
			}
			setStatus(true);
		} catch (error) {
			navigate('/');
		}
	};

	return (status && !loading) ? <React.Fragment>{ component }</React.Fragment> : <Loading/>;
};

export default UnAuthGuard;